let screenHeight, screenWidth, wind, balloonTop, header, formWrapperY;

Number.prototype.map = function (in_min, in_max, out_min, out_max) {
  return (this - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
};

function hideKeyboard(form) {
  let formInputs = form.querySelectorAll('input');
  for (var i = formInputs.length - 1; i >= 0; i--) {
    formInputs[i].disabled = true;
  }
  document.activeElement.blur();
  let inputs = document.querySelectorAll('input');
  for(let i=0; i < inputs.length; i++) {
    inputs[i].blur();
  }
};

function updateScreenValues(){
  screenHeight = window.innerHeight;
  screenWidth = window.innerWidth;
  balloonTop = header.offsetTop + header.clientHeight;
};

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
};

class FormHandler {
  constructor(form){
    this.elem = form;
    this.formWrapper = form.parentElement;
    this.killScreenElem = document.querySelector('.killscreen');
    this.winScreenElem = document.querySelector('.winscreen');
    this.buttons = form.querySelectorAll('button');
    this.userName = '';
    this.save = (e) => {
      e.preventDefault();
      hideKeyboard(e.target.parentElement);
      let para = e.target.parentElement.querySelector('.paragraph').innerHTML;
      let buttonVal = e.target.value;
      if(!buttonVal){
        let fields = e.target.parentElement.querySelectorAll('.decisionfield');
        for (var i = fields.length - 1; i >= 0; i--) {
          if(fields[i].value){
            buttonVal += fields[i].value + ' ';
          }
          if(fields[i].classList.contains('name')){
            this.userName = fields[i].value;
          }
        }
      }
      this.moveToNextForm(e.target.parentElement, e.target);
      var timeNow = new Date();
      var timeStamp = timeNow.getDate() + "." + timeNow.getMonth() + " " + timeNow.getHours() + ":" + timeNow.getMinutes() + ":" + timeNow.getSeconds();
      let xhttp = new XMLHttpRequest();
      let answer = encodeURIComponent(timeStamp + ': ' + para + ': ' + buttonVal);
      xhttp.open("GET", "../../public/write.php?name="+answer, true);
      xhttp.send();
    };
    formWrapperY = 0;
  }
  init() {
    for (var i = this.buttons.length - 1; i >= 0; i--) {
      this.buttons[i].addEventListener('click', this.save, false);
    }
  }
  deactivateForm(button){
    button.classList.add('js-active');
    button.parentElement.classList.add('js-deactivated');
  }
  moveToNextForm(form, pressedButton){
    this.deactivateForm(pressedButton);
    let formHeight = form.offsetHeight;
    formWrapperY = formWrapperY + formHeight;
    if(pressedButton.classList.contains('decision-badend')){
      this.showKillScreen(pressedButton.value);
    }else if(pressedButton.classList.contains('decision-goodend')){
      this.showWinScreen();
    }else{
      this.formWrapper.style.transform = 'translateY(' + formWrapperY*-1 + 'px)';
      form.nextElementSibling.classList.remove('js-deactivated');
    }
  }
  showKillScreen(lastAnswer) {
    this.killScreenElem.querySelector('.killscreen--answer').innerHTML = lastAnswer;
    this.killScreenElem.classList.add('js-active');
    let audio = new Audio('../public/fatality.mp3');
    audio.play();
  }
  showWinScreen() {
    console.log('name: ' + this.userName);
    if(this.userName){
      this.winScreenElem.querySelector('.winscreen--name').innerHTML = this.userName;
    }
    this.winScreenElem.classList.add('js-active');
    let audio = new Audio('../public/zelda.mp3');
    audio.play();
  }
}

class Balloon {
  constructor(elem, x) {
    this.elem = elem;
    this.speedY = Math.random()*2;
    this.speedX = 0;
    this.gravity = 0.05;
    this.y = screenHeight;
    this.x = x || getRandomInt(screenWidth - 50);
    this.lastY = 0;
    this.lastX = 0;
    this.yOffset = 0;
    this.xOffset = 0;
    this.elemWidth = elem.clientWidth;
    this.isDragged = false;
    this.dragStartY = 0;
    this.dragDistanceY = 0;
    this.dragStartX = 0;
    this.dragDistanceX = 0;
    this.down = (e) => {
      e.preventDefault();
      this.isDragged = true;
      this.dragStartY = e.screenY || e.touches[0].screenY;
      this.yOffset = this.y;
      this.dragDistanceY = this.yOffset;
      this.dragStartX = e.screenX || e.touches[0].screenX;
      this.xOffset = this.x;
      this.dragDistanceX = this.xOffset;
    };
    this.up = (e) => {
      this.isDragged = false;
      this.dragDistanceY = 0;
      this.dragStartY = 0;
      this.dragDistanceX = 0;
      this.dragStartX = 0;
    };
    this.drag = (e) => {
      screenY = e.screenY || e.touches[0].screenY;
      screenX = e.screenX || e.touches[0].screenX;
      this.dragDistanceY = screenY - this.dragStartY + this.yOffset;
      this.dragDistanceX = screenX - this.dragStartX + this.xOffset;
    };
  }
  
  init() {
    this.elem.addEventListener('mousedown', this.down, false);
    this.elem.addEventListener('touchstart', this.down, false);
    this.elem.addEventListener('mouseup', this.up, false);
    this.elem.addEventListener('touchend', this.up, false);
    window.addEventListener('mousemove', this.drag, false);
    window.addEventListener('touchmove', this.drag, false);
  }
  
  animate() {
    this.move();
    this.draw();
    requestAnimationFrame(this.animate.bind(this));
  }
  
  move() {
    if(this.isDragged == false){
      this.y = this.y - this.speedY;
      this.speedY = this.speedY + this.gravity;
    }else{
      this.y = this.dragDistanceY;
      this.x = this.dragDistanceX;
    }
    //bounce of from top
    if(!this.isDragged){
      if(this.y <= balloonTop && this.speedY > 0){
        this.speedY = this.speedY*-0.48;
      }
    }else{
      if(this.y <= balloonTop){
        this.y = balloonTop;
      }
    }
    //stop at left and right
    if(this.x <= 0){
      this.x = 0;
    }else if(this.x >= screenWidth - this.elemWidth){
      this.x = screenWidth - this.elemWidth;
    }
    //add friction if on top
    if(this.y > 1){
      this.x = this.x - this.speedX;
    }else{
      this.x = this.x - this.speedX/2;
    }
  }
  
  draw() {
    // draw only if values have changed!
    if(Math.round(this.lastY) != Math.round(this.y) || Math.round(this.lastX) != Math.round(this.x)){
      this.elem.style.transform = 'translateZ(0) translateY('+ this.y + 'px)' + 'translateX(' + this.x + 'px)';
      this.lastY = this.y;
      this.lastX = this.x;
    }
  }
}

window.onload = function(){

  let forms = document.querySelectorAll('form');
  for (var i = forms.length - 1; i >= 0; i--) {
    let form = new FormHandler(forms[i]);
    form.init();
  }
  
  header = document.querySelector('.headline1');
  updateScreenValues();
// Classes are used just like ES5 constructor functions:
  let balloon1 = new Balloon(document.querySelector('.balloon.red'), (screenWidth/2 - screenWidth*0.66*0.66));
  let balloon2 = new Balloon(document.querySelector('.balloon.blue'), (screenWidth/2));
  balloon1.init();
  balloon2.init();

  // remove loader or wait for 3 seconds to have passed
  let removeLoaderIn = 0;
  let loadEnd = new Date();
  if((loadEnd - loadStart) < 3000){
    removeLoaderIn = 3000 - (loadEnd - loadStart);
  }else{
    removeLoaderIn = 0;
  }

  setTimeout(function () {
    // remove loader and reveal document
    let loader = document.querySelector('.loader');
    loader.remove();

    balloon1.animate();
    balloon2.animate();

  }, removeLoaderIn);
};
window.onresize = updateScreenValues;